import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-yellow-400 via-mustard to-darkGreen flex items-center justify-center">
      <div className="text-center px-20 py-12 bg-white rounded-3xl shadow-3xl">
        <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-darkGreen to-mustard">
          Coming Soon!
        </h1>
        <p className="text-lg text-gray-600 mt-4">
          We are working hard to bring you <br/>an amazing experience. Stay tuned for the full launch!
        </p>
        <div className="mt-10 flex justify-center space-x-6">
          <a
            href="https://www.instagram.com/twendesagumo/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="text-darkGreen hover:text-mustard transition-colors"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>

        <p className="text-gray-500 mt-6">
          Follow us on social media for updates!
        </p>
      </div>
    </div>
  );
};

export default HomePage;
